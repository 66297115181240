import React from 'react';
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "../layouts/pl"
import Fade from 'react-reveal/Fade';

// ICONS
import { FaWater } from 'react-icons/fa';
import { TiWeatherSunny } from 'react-icons/ti';
import { TiLocationOutline } from 'react-icons/ti';
import { FaSwimmingPool } from 'react-icons/fa';
import { IoIosFitness } from "react-icons/io";


const Oferta = (props) => {
    const data = useStaticQuery(graphql` 
        query {
            desktopImage: file(relativePath: { eq: "images/photos/oferta/oferta-main.jpg" }) {
                childImageSharp {
                    fluid(quality: 100){
                    ...GatsbyImageSharpFluid
                    }
                }   
            }
            mobileImage: file(relativePath: { eq: "images/photos/oferta/oferta-main-mobile.jpg" }) {
                childImageSharp {
                    fluid(quality: 50){
                        ...GatsbyImageSharpFluid
                    }
                }   
            }
        }
    `);

    const sources = [
        data.mobileImage.childImageSharp.fluid,
        {
            ...data.desktopImage.childImageSharp.fluid,
            media: `(min-width: 768px)`,
        },
    ];


    return (
        <Layout location={props.location}>
            <Fade up>
                <section>
                    <Img fluid={sources} alt="Dziwnowek apartamenty Amber" />
                    <h1 className="title">OFERTA</h1>
                    <div className="content-container">
                        <h1>Idealne wakacje i niezapomniane wrażenia</h1>
                        <p>Oferujemy Państwu komfortowe, całoroczne apartamenty w nadmorskich  miejscowościach Dziwnówek i Dziwnów (woj. zachodniopomorskie) w odległości ok. 250 m i 700 m od pięknych , szerokich plaż.</p>
                        <p>Apartamenty Amber 1 i Amber 2  zlokalizowane są w kompleksie Porta Mare na osiedlu Słoneczne Tarasy w Dziwnówku, natomiast Apartament  Amber 3  mieści się w kompleksie Porta Mare Marina w Dziwnowie.</p>
                        <p>Lokale znajdują się na 1, 2 i 3 piętrze w budynkach z cichobieżnymi windami. Apartamenty cztero i sześcioosobowe składają się z salonu wraz z w pełni wyposażonym aneksem kuchennym, osobnej jednej lub dwóch sypialni z łożem małżeńskim, łazienki, balkonu oraz przynależnego do każdego apartamentu miejsce postojowego w garażu podziemnym.</p>
                        <p>W cenie otrzymacie Państwo nowocześnie urządzone mieszkania z wi-fi i klimatyzacją. Apartamenty Amber to miejsca gdzie spędzicie Państwo mile i wygodnie swój czas urlopowy. Korzystając z naszej lokalizacji wszędzie macie Państwo blisko. W pobliżu znajdują się liczne bary i restauracje, w których serwują wyśmienite posiłki.</p>
                        <br />
                        <br />
                        <p>Dziwnówek i Dziwnów  to spokojne i niewielkie  miejscowości nadmorskie, oddalone od siebie jedynie ok. 3 km  sprzyjające rodzinnemu wypoczynkowi.</p>
                        <p>Położone są u nasady Mierzei Dziwnowskiej, pomiędzy Morzem Bałtyckim a Zatoką Wrzosowską.</p>
                        <p>Największą atrakcją Dziwnówka i Dziwnowa  są czyste wody Bałtyku , piaszczysta, szeroka plaża oraz sąsiedztwo lasów sosnowych.</p>
                        <p>W okolicy panują znakomite warunki do uprawiania różnych form aktywnego wypoczynku, w tym sportów wodnych, turystyki rowerowej i pieszej. W pobliżu znajduje się szkoła Windsurfingu oraz  wypożyczalnie rowerów.</p>
                        <p>Świetnie zlokalizowane ścieżki rowerowe przebiegające wzdłuż nadmorskiego wybrzeża wśród lasów sosnowych dodają uroku oraz sprawiają, że można całkowicie się wyciszyć i zrelaksować. </p>
                        <br />

                    </div>
                </section>
            </Fade>

            <Fade up>
                <section className="facts">
                    <h2>Dziwnówek</h2>
                    <div>
                        <span>
                            <p>250 m</p>
                            <FaWater />
                            <p>do morza</p>
                            <span />
                        </span>
                        <span>
                            <p>200 m</p>
                            <TiLocationOutline />
                            <p>do centrum</p>
                            <span />

                        </span>
                        <span>
                            <p>450 m</p>
                            <TiWeatherSunny />
                            <p>do zalewu</p>
                            <span />

                        </span>
                        <span>
                            <p>300 m</p>
                            <FaSwimmingPool />
                            <p>do basenów i spa</p>
                            <span />

                        </span>
                    </div>
                    <span className='divider' />
                    <h2>Dziwnów</h2>
                    <div>
                        <span>
                            <p>500 m</p>
                            <FaWater />
                            <p>do morza</p>
                            <span />
                        </span>
                        <span>
                            <p>300 m</p>
                            <TiLocationOutline />
                            <p>do centrum</p>
                            <span />

                        </span>
                        <span>
                            <p>na miejscu</p>
                            <FaSwimmingPool />
                            <p>basen, jacuzzi i sauny</p>
                            <span />
                        </span>
                        <span>
                            <p>na miejscu</p>
                            <IoIosFitness />
                            <p>sala fitness</p>
                            <span />
                        </span>
                    </div>
                </section>
            </Fade>
        </Layout>
    )
}


export default Oferta
